#Main_article {
  flex-grow: 1;
  width: 100%;
  order: 1;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  #Main_article {
    flex-direction: column;
  }
}
