/*header page*/
#Header_article {
  z-index: 100;
  font-family: "Pacifico", cursive;
  order: 1;
  margin: center;
  border-bottom: solid rgba(0, 0, 0, 0.671) 1px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.137);
}

/*header content container*/
#Header_content_container {
  width: 1200px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: space-between;
}

@keyframes header_blinker {
  20% {
    background-color: var(--orange);
  }
  30% {
    background-color: var(--blue);
  }

  50% {
    background-color: var(--orange);
  }
  100% {
    background-color: var(--blue);
  }
}

@media screen and (max-width: 600px) {
  #timer {
    font-size: 12pt;
  }
}
