#Footer_article {
  font-family: "Roboto", sans-serif;
  background-color: white;
  order: 3;
  padding: 10px;
  width: 100%;
  height: 100px;
  overflow: hidden;
  /* box-shadow: 0 0 30px black; */
}

#Footer_container {
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

#Footer_copyright,
#Footer_clock {
  color: var(--black);
  font-weight: 300;
  font-size: 11pt;
  text-align: center;
  flex-grow: 1;
  margin: auto;
}

@keyframes footer_blinker {
  0% {
    background-color: var(--white);
  }
  30% {
    background-color: rgba(165, 161, 161, 0.404);
  }

  60% {
    background-color: var(--white);
  }
  100% {
    background-color: rgba(165, 161, 161, 0.404);
  }
}

@media screen and (max-width: 700px) {
  #Footer_copyright {
    font-size: 10pt;
  }
  #Nav_article i {
    font-size: 16pt;
  }
}

@media screen and (max-width: 1200px) {
  #Footer_article {
  }
}
