/*......Page scope.......*/
* {
  font-family: "Raleway", sans-serif;
}
#Login_article {
  min-height: 100vh;
  background-color: var(--white);
  align-items: center;
}

/* ...........loader.............. */
#Login_loaderImg_div {
  font-size: 15pt;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

/*......Header and Loginform Container.......*/
#Login_main {
  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  /* padding: 0 30px; */
}
#Login_signupShow_text {
  cursor: pointer;
}
#Login_signupShow_text:hover {
  transform: scale(0.92);
}
#Login_loginShow_text {
  cursor: pointer;
}
#Login_loginShow_text:hover {
  transform: scale(0.92);
}

#Login_loginLogo_text {
  font-family: "Exo", sans-serif;
  font-size: 80pt;
  font-weight: 700;
  text-align: center;
  line-height: 45px;
  color: #01796f;
  position: relative;
}
#Login_subLoginLogo_text {
  font-family: "Pacifico", cursive;
  font-weight: 300;
  font-size: 35pt;
  color: var(black);
  text-align: center;
}

#Login_loginForm_container {
}

#Login_loginFrom_form {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: var(--brightestWhite);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.486);
  width: 450px;
  overflow: hidden;
}

#Login_loginFrom_form input {
  font-size: 12pt;
}

#Login_loginFrom_form button {
  background-color: #01796f;
  color: var(--brightestWhite);
  font-weight: bold;
  font-size: 14pt;
  width: 100%;
}

#Login_footer {
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 60px 100px rgba(18, 118, 175, 0.486);
  width: 100%;
  padding: 30px;
}

#Login_copyright_text {
  font-weight: 300;
}

@media screen and (max-height: 600px) {
  #Login_loginLogo_text {
    font-size: 50pt;
  }
  #Login_subLoginLogo_text {
    font-size: 30pt;
  }
  #Login_loginFrom_form {
    width: 380px;
    gap: 5px;
  }
  #Login_main {
    gap: 20px;
  }
  #Login_loginFrom_form input,
  #Login_loginFrom_form button {
    font-size: 14px;
  }
}

@media screen and (max-height: 500px) {
  #Login_loginLogo_text {
    font-size: 40pt;
  }
  #Login_subLoginLogo_text {
    font-size: 20pt;
  }
  #Login_loginFrom_form {
    width: 380px;
  }
  #Login_loginFrom_form input,
  #Login_loginFrom_form button {
    font-size: 12px;
  }
  #Login_main {
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  #Login_loginLogo_text {
    font-size: 50pt;
  }
  #Login_subLoginLogo_text {
    font-size: 30pt;
  }
  #Login_loginFrom_form {
    width: 380px;
  }
}
@media screen and (max-width: 500px) {
  #Login_loginLogo_text {
    font-size: 45pt;
  }
  #Login_subLoginLogo_text {
    font-size: 25pt;
  }
  #Login_loginFrom_form {
    width: 320px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}

@media screen and (max-width: 425px) {
  #Login_loginLogo_text {
    font-size: 40pt;
  }
  #Login_subLoginLogo_text {
    font-size: 23pt;
  }
  #Login_loginFrom_form {
    width: 300px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}

@media screen and (max-width: 350px) {
  #Login_loginLogo_text {
    font-size: 35pt;
  }
  #Login_subLoginLogo_text {
    font-size: 15pt;
  }
  #Login_loginFrom_form {
    width: 250px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}
