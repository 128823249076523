#MountPosts_article {
  overflow: hidden;
}
#MountPosts_content_container {
  padding: 10px 100px;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--black);
  flex-grow: 1;
  overflow: auto;
}
#MountPosts_content_container p {
  font-family: "Ropa Sans", sans-serif;
}

#MountPosts_content_container li {
  background-color: var(--special_black);
  padding: 20px;
  height: fit-content;
  border-radius: 10px;
  gap: 15px;
  justify-content: space-between;
  position: relative;
  color: black;
}
.note_options_div p {
  color: var(--white);
  font-family: "Ropa Sans", sans-serif;
}

.details_div p {
  color: var(--white);
  font-size: 13pt;
}
.comment_li {
  background-color: var(--gray) !important;
  padding: 15px !important;
  border-radius: 0 !important;
}
.comment_li {
  font-family: "Ropa Sans", sans-serif;
  font-size: 14pt;
}
.commentlist_ul {
  background-color: var(--black);
  gap: 5px;
  overflow: auto;
}
.comment_input {
  background-color: var(--black);
  color: white;
  padding: 15px !important;
}
.comment_input::placeholder {
  color: white;
}
.comments_div {
  order: 2;
  max-height: 200px;
  gap: 5px;
}
.postername_p {
  background-color: var(--red) !important;
  color: var(--white) !important;
}
.note_p {
  font-size: 16pt;
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word !important;
}

#MountPosts_article {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.075);
  flex-grow: 1;
  order: 2;
}
.MountPosts_date {
  font-family: "Roboto", sans-serif;
  font-size: 11pt;
  text-align: right;
}

.note_options_div {
  justify-content: space-between;
  order: 1;
  padding-right: 0;
  height: fit-content;
  gap: 10px;
}
.options_div {
  position: absolute;
}

.postername_p {
  background-color: var(--red);
}
.details_div {
  gap: 10px;
  justify-content: flex-end;
  order: 2;
}

#MountPosts_content_container li p {
  padding: 5px;
}

.MountPosts_postOptionsContainer {
  gap: 10px;
}

.MountPosts_postOptionsContainer p {
  background-color: #01796f;
}

@media screen and (max-width: 1000px) {
  #MountPosts_content_container {
    padding: 20px 5px;
    gap: 20px;
  }
  #MountPosts_content_container p {
    font-size: smaller;
  }
  #MountPosts_content_container .note_p {
    font-size: 14pt;
  }
  #MountPosts_content_container .comment_input {
    font-size: 10pt;
  }
  #MountPosts_content_container .comment_li {
    font-size: 12pt;
  }
}

@media screen and (max-width: 850px) {
  .details_div {
    flex-direction: column !important;
    align-items: flex-end;
  }
}
