@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: background-color cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.7s,
    width cubic-bezier(1, 0.46, 0.33, 1) 0.7s,
    height cubic-bezier(1, 0.46, 0.33, 1) 0.7s,
    transform cubic-bezier(1, 0.46, 0.33, 1) 0.1s,
    font-size cubic-bezier(1, 0.46, 0.33, 1) 0.7s;
  font-family: "Raleway", sans-serif;
}
*::selection {
  background-color: var(--blue);
}

:root {
  --brightestWhite: #ffffff;
  --blue: #1877f2;
  --blue2: #6fa7dc;
  --white: #f0f2f5;
  --gray: rgb(224, 214, 214);
  --gray_for_read: #696969;
  --pacifico: "Pacifico", cursive;
  --orange: #f04e1f;
  --green: rgba(93, 126, 77, 0.883);
  --black: #18191a;
  --black2: #3a3b3c;
  --black3: #242526;
  --black_for_appform: black;
  --special_black: #302e2e;
  --newBlack: #;
  --red: #ff2f2d;
  --background_menu: rgba(0, 0, 0, 0.459);
}
#root {
  height: 100vh;
  background-color: var(--black);
  overflow: hidden;
}
#app_page {
  flex-grow: 1;
  overflow: hidden;
}
#server_answer {
  position: fixed;
  bottom: 50px;
  right: 0px;
  background: var(--black);
  font-size: 10pt;
  width: 0;
  color: var(--white);
  z-index: 200;
}
#server_answer_message {
  padding: 20px;
  font-size: 12pt;
  min-width: fit-content;
}

::-webkit-scrollbar {
  display: none;
}

.slide-top {
  -webkit-animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

a {
  text-decoration: none;
  color: inherit;
}

i:hover {
  transform: scale(0.95);
  cursor: pointer;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

li {
  list-style: none;
}
label {
  color: white;
}

input,
textarea,
select {
  font-weight: 300;
  outline-style: none;
  border: none;
  font-size: 12pt;
  padding: 10px;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.226); */
  width: 100%;
  resize: none;
  background-color: var(--white);
}
input::selection {
  background-color: var(--green);
}
button {
  border: none;
  padding: 8px;
  border-radius: 8px;
  outline-style: none;
  cursor: pointer;
}
button:hover {
  transform: scale(0.99);
}

@media screen and (max-width: 1000px) {
  #server_answer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 0;
  }
  #server_answer_message {
    font-size: 10pt !important;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-21 23:27:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/*......................................*/
.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
