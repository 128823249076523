@import url(https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: background-color cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.7s,
    width cubic-bezier(1, 0.46, 0.33, 1) 0.7s,
    height cubic-bezier(1, 0.46, 0.33, 1) 0.7s,
    transform cubic-bezier(1, 0.46, 0.33, 1) 0.1s,
    font-size cubic-bezier(1, 0.46, 0.33, 1) 0.7s;
  font-family: "Raleway", sans-serif;
}
*::selection {
  background-color: #1877f2;
  background-color: var(--blue);
}

:root {
  --brightestWhite: #ffffff;
  --blue: #1877f2;
  --blue2: #6fa7dc;
  --white: #f0f2f5;
  --gray: rgb(224, 214, 214);
  --gray_for_read: #696969;
  --pacifico: "Pacifico", cursive;
  --orange: #f04e1f;
  --green: rgba(93, 126, 77, 0.883);
  --black: #18191a;
  --black2: #3a3b3c;
  --black3: #242526;
  --black_for_appform: black;
  --special_black: #302e2e;
  --newBlack: #;
  --red: #ff2f2d;
  --background_menu: rgba(0, 0, 0, 0.459);
}
#root {
  height: 100vh;
  background-color: #18191a;
  background-color: var(--black);
  overflow: hidden;
}
#app_page {
  flex-grow: 1;
  overflow: hidden;
}
#server_answer {
  position: fixed;
  bottom: 50px;
  right: 0px;
  background: #18191a;
  background: var(--black);
  font-size: 10pt;
  width: 0;
  color: #f0f2f5;
  color: var(--white);
  z-index: 200;
}
#server_answer_message {
  padding: 20px;
  font-size: 12pt;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

::-webkit-scrollbar {
  display: none;
}

.slide-top {
  animation: slide-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

a {
  text-decoration: none;
  color: inherit;
}

i:hover {
  transform: scale(0.95);
  cursor: pointer;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

li {
  list-style: none;
}
label {
  color: white;
}

input,
textarea,
select {
  font-weight: 300;
  outline-style: none;
  border: none;
  font-size: 12pt;
  padding: 10px;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.226); */
  width: 100%;
  resize: none;
  background-color: #f0f2f5;
  background-color: var(--white);
}
input::selection {
  background-color: rgba(93, 126, 77, 0.883);
  background-color: var(--green);
}
button {
  border: none;
  padding: 8px;
  border-radius: 8px;
  outline-style: none;
  cursor: pointer;
}
button:hover {
  transform: scale(0.99);
}

@media screen and (max-width: 1000px) {
  #server_answer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 0;
  }
  #server_answer_message {
    font-size: 10pt !important;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-21 23:27:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
/*......................................*/
.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-top {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

#Footer_article {
  font-family: "Roboto", sans-serif;
  background-color: white;
  order: 3;
  padding: 10px;
  width: 100%;
  height: 100px;
  overflow: hidden;
  /* box-shadow: 0 0 30px black; */
}

#Footer_container {
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

#Footer_copyright,
#Footer_clock {
  color: var(--black);
  font-weight: 300;
  font-size: 11pt;
  text-align: center;
  flex-grow: 1;
  margin: auto;
}

@keyframes footer_blinker {
  0% {
    background-color: var(--white);
  }
  30% {
    background-color: rgba(165, 161, 161, 0.404);
  }

  60% {
    background-color: var(--white);
  }
  100% {
    background-color: rgba(165, 161, 161, 0.404);
  }
}

@media screen and (max-width: 700px) {
  #Footer_copyright {
    font-size: 10pt;
  }
  #Nav_article i {
    font-size: 16pt;
  }
}

@media screen and (max-width: 1200px) {
  #Footer_article {
  }
}

/*header page*/
#Header_article {
  z-index: 100;
  font-family: "Pacifico", cursive;
  order: 1;
  margin: center;
  border-bottom: solid rgba(0, 0, 0, 0.671) 1px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.137);
}

/*header content container*/
#Header_content_container {
  width: 1200px;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: space-between;
}

@keyframes header_blinker {
  20% {
    background-color: var(--orange);
  }
  30% {
    background-color: var(--blue);
  }

  50% {
    background-color: var(--orange);
  }
  100% {
    background-color: var(--blue);
  }
}

@media screen and (max-width: 600px) {
  #timer {
    font-size: 12pt;
  }
}

/*......todoAddFriend.....*/
#AddFriend_article {
  order: 2;
  background-color: var(--black);
  height: 0;
  overflow: hidden;
}

#AddFriend_content_container {
  overflow: hidden;
  animation-name: width;
  animation-duration: 1s;
  width: 100%;
  padding: 30px;
  box-shadow: 0 0px 10px rgba(56, 46, 46, 0.24);
  z-index: 1;
  grid-gap: 30px;
  gap: 30px;
}

#AddFriend_i {
  text-align: center;
  color: var(--white);
  font-size: 30pt;
}

#AddFriend_addFriend_results {
  flex-grow: 1;
  overflow: auto;
  overflow-wrap: break-word;
  width: 100%;
}

#AddFriend_addFriend_results li {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 13pt;
  text-align: center;
  padding: 20px 20px;
  background-color: var(--black2);
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

#AddFriend_addFriend {
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

#AddFriend_addFriend input {
  border-radius: 8px;
  outline-style: none;
  border: none;
  width: 250px;
  font-size: 14pt;
  padding: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.226);
  font-family: "Roboto", sans-serif;
  flex-direction: 1;
}

@keyframes width {
  0% {
    width: 0;
  }
  20% {
    width: 40px;
  }
}

#Posts_article {
  flex-grow: 1;
  order: 3;
  align-items: center;
  overflow: auto;
}
#Posts_content_container {
  order: 1;
  flex-grow: 1;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  #Posts_content_container {
    width: 100%;
  }
}

#Main_article {
  flex-grow: 1;
  width: 100%;
  order: 1;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  #Main_article {
    flex-direction: column;
  }
}

#Friends_article {
  order: 5;
  z-index: 3;
}

#Friends_content_container {
  order: 2;
  width: 0;
  overflow: hidden;
  height: 100%;
}

#form_notes_dashboard h2 {
  font-family: "Pacifico", cursive;
  font-weight: 300;
  padding: 10px;
  background-color: var(--orange);
  text-align: center;
  color: var(--white);
  font-size: 14pt;
  width: 100%;
}

#Friends_control_door {
  background-color: var(--black);
  justify-content: center;
  align-items: center;
  order: 1;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
  border-left: solid rgba(0, 0, 0, 0.459) 2px;
  z-index: 99;
}
#Friends_control_door i {
  color: var(--white);
  font-size: 16pt;
  padding: 10px;
  animation-name: arrow_control_color;
  animation-duration: 1.5s;
}

input[value=""]::-webkit-datetime-edit {
  color: transparent;
}

@media screen and (max-width: 1200px) {
  #Friends_content_container {
    background-color: var(--red);
    width: 100% !important;
  }
  #menuaside_main_container li {
    font-size: 7pt;
  }
  #menuaside_main_container {
    font-size: 7pt;
  }
}

@keyframes width {
  0% {
    width: 0;
  }
  20% {
    width: 40px;
  }
}
@media screen and (max-width: 1200px) {
  #Friends_control_door {
    background-color: var(--black2);
  }
  #Friends_control_door i {
    font-size: 16pt;
  }
  #DropHorizontally_article i {
    font-size: 16pt;
  }
  #Chat_goback_icon {
    font-size: 16pt;
  }
}
@media screen and (max-width: 1200px) {
  #Friends_content_container {
    overflow: hidden;
    height: 0;
    width: 0;
  }
  #Friends_article {
    flex-direction: column;
    display: none;
  }
}

/* Search */

#Search_section {
  overflow: hidden;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
#SearchUsers_input {
  outline-style: none;
  font-size: 12pt;
  padding: 10px;
  box-shadow: none !important;
  border-radius: 0 !important ;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
}

#Search_button {
  border: none;
  background-color: var(--green);
  border-radius: 0;
  color: var(--white);
  font-size: 12pt;
  height: 100%;
}

/* Search */

#SearchPosts_article {
  padding: 5px 0;
  order: 3;
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.13) 1px;
  box-shadow: 2px 4px -10px black;
  background-color: #01796f;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  z-index: 100;
  position: relative;
}
#SearchPosts_content_container {
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  display: none;
  padding: 10px;
}
#SearchPosts_control_section {
  color: var(--white);
  align-items: center;
  justify-content: center;
}

#SearchPosts_subject_container {
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
#SearchPosts_category_container {
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
#SearchPosts_keyword {
  outline-style: none;
  font-size: 12pt;
  padding: 10px;
  box-shadow: none !important;
  border-radius: 0 !important ;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
}
#SearchPosts_category {
  outline-style: none;
  font-size: 12pt;
  padding: 10px;
  box-shadow: none !important;
  border-radius: 0 !important ;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
}

#SearchPosts_subject {
  outline-style: none;
  font-size: 12pt;
  padding: 10px;
  box-shadow: none !important;
  border-radius: 0 !important ;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
}

#SearchPosts_Buttons_container {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
#SearchPosts_searchButton {
  border: none;
  background-color: var(--special_black);
  border-radius: 0;
  color: var(--white);
  font-size: 12pt;
}
#SearchPosts_clearButton {
  border: none;
  background-color: var(--special_black);
  border-radius: 0;
  color: var(--white);
  font-size: 12pt;
}
#SearchPosts_control_close_icon {
  display: none;
  padding: 5px 0;
}
#SearchPosts_control_icon_container {
  color: var(--white);
  padding: 5px 0;
}
@media screen and (max-width: 700px) {
  #SearchPosts_category,
  #SearchPosts_subject,
  #SearchPosts_searchButton,
  #SearchPosts_clearButton,
  #SearchPosts_keyword {
    font-size: 8pt;
    width: 200px;
  }
  #SearchPosts_content_container {
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
  }
  #SearchPosts_Buttons_container {
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
  }
}

/*Logo*/
#Logo_text {
  font-family: "Questrial", sans-serif;
  color: var(--white);
  text-align: center;
  letter-spacing: 1px;
  font-size: 30pt;
  padding: 10px 40px;
}
#Logo_section {
  padding: 10px;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  #Logo_text {
    font-size: 20pt;
  }
}
@media screen and (max-width: 600px) {
  #Logo_text {
    font-size: 15pt !important;
    padding: 10px;
    border-right: solid var(--white) 4px;
  }
}

#Notifications_dropMenu_container {
  display: none;
  position: fixed;
  background-color: var(--background_menu);
  height: 50%;
  overflow: auto;
  color: white;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: var(--red) 10px solid;
  width: 20%;
}

#Notifications_dropMenu_container li {
  font-family: "Roboto", sans-serif;
  background-color: rgb(212, 48, 48);
  padding: 20px;
  cursor: pointer;
  width: 80%;
}

#Notifications_dropMenu_container div {
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
#Notifications_dropMenu_container i {
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
}
#Notifications_alert {
  color: red;
  position: absolute;
  font-size: 10px !important;
  z-index: 1;
}

@media screen and (max-width: 1000px) {
  #Notifications_dropMenu_container {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  #Notifications_dropMenu_container {
    width: 100%;
    left: 0;
  }
}

/*Nav*/
#Nav_article i {
  font-size: 15pt;
  color: rgba(255, 255, 255, 0.904);
  cursor: pointer;
}

#Nav_article {
  grid-gap: 35px;
  gap: 35px;
  align-items: center;
  padding: 14px;
}

#Nav_article i:hover {
  transform: scale(0.96);
}

@media screen and (max-width: 1200px) {
  #Header_content_container h2 {
    font-size: 20pt;
  }
  #Nav_article i {
    font-size: 10pt !important;
  }
  #Nav_article {
    grid-gap: 17px;
    gap: 17px;
  }
}

#InputPost_article {
  order: 1;
  width: 100%;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.473);
  z-index: 1;
  margin: auto;
}
#InputPost_textarea_container {
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;
}

#InputPost_textarea {
  font-size: 13pt;
  line-height: 20pt;
  border-bottom-left-radius: 10px;
  height: 60px;
  min-height: 60px;
  padding: 15px 20px;
  background-color: var(--special_black);
  color: white;
}
#InputPost_textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}
#InputPost_textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}
#InputPost_textarea::placeholder {
  color: rgba(255, 255, 255, 0.658);
}
#InputPost_inputs_container {
  margin: 0 auto;
  padding-top: 0;
  background-color: var(--special_black);
  /* border-left: solid 10px black;
  border-right: solid 10px black; */
  border-bottom-right-radius: 10px;
}

#InputPost_post_button {
  color: white;
  font-weight: 300;
  border: 0;
  font-size: 14pt;
  padding: 10px 30px;
  border-radius: 0px;
  font-family: "Raleway", sans-serif;
  border-bottom-right-radius: 10px;
  background-color: #01796f;
}

#InputPost_greeting_container {
  justify-content: center;
  background-color: var(--green);
  z-index: 1;
}

/* #InputPost_greeting_text {
  font-family: "Pacifico", cursive;
  font-size: 20pt;
  white-space: nowrap;
  padding: 20px;
  color: var(--white);
  text-align: center;
} */

#InputPost_category,
#InputPost_subject,
#InputPost_resourse,
#InputPost_page {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 11pt;
  border: solid rgba(0, 0, 0, 0.068) 1px;
  background-color: var(--special_black);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.404);
}

#InputPost_resourse::-webkit-input-placeholder, #InputPost_page::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

#InputPost_resourse:-ms-input-placeholder, #InputPost_page:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

#InputPost_resourse::placeholder,
#InputPost_page::placeholder {
  color: rgba(255, 255, 255, 0.658);
}
#InputPost_refresh_icon:hover {
  transform: none;
}

@media screen and (max-width: 1000px) {
  #InputPost_textarea {
    font-size: 12pt;
  }
  #InputPost_textarea_container {
    padding: 0 5px;
    flex-direction: column;
  }
  #InputPost_textarea {
    border-bottom-left-radius: 0px;
  }
  #InputPost_category,
  #InputPost_subject,
  #InputPost_resourse,
  #InputPost_page {
    font-size: 10pt;
  }

  #InputPost_post_button {
    font-size: 12pt;
    border-bottom-right-radius: 0px;
  }
}
@media screen and (max-width: 600px) {
  #InputPost_textarea {
    font-size: 12pt;
  }
  #InputPost_greeting_text {
    font-size: 12pt;
    white-space: normal;
  }
  #InputPost_category,
  #InputPost_subject,
  #InputPost_resourse,
  #InputPost_page {
    font-size: 10pt;
  }
  #InputPost_post_button {
    font-size: 10pt;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1300px) {
  #InputPost_textarea_container,
  #InputPost_inputs_container {
    width: 100%;
  }
}

#MountPosts_article {
  overflow: hidden;
}
#MountPosts_content_container {
  padding: 10px 100px;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--black);
  flex-grow: 1;
  overflow: auto;
}
#MountPosts_content_container p {
  font-family: "Ropa Sans", sans-serif;
}

#MountPosts_content_container li {
  background-color: var(--special_black);
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  position: relative;
  color: black;
}
.note_options_div p {
  color: var(--white);
  font-family: "Ropa Sans", sans-serif;
}

.details_div p {
  color: var(--white);
  font-size: 13pt;
}
.comment_li {
  background-color: var(--gray) !important;
  padding: 15px !important;
  border-radius: 0 !important;
}
.comment_li {
  font-family: "Ropa Sans", sans-serif;
  font-size: 14pt;
}
.commentlist_ul {
  background-color: var(--black);
  grid-gap: 5px;
  gap: 5px;
  overflow: auto;
}
.comment_input {
  background-color: var(--black);
  color: white;
  padding: 15px !important;
}
.comment_input::-webkit-input-placeholder {
  color: white;
}
.comment_input:-ms-input-placeholder {
  color: white;
}
.comment_input::placeholder {
  color: white;
}
.comments_div {
  order: 2;
  max-height: 200px;
  grid-gap: 5px;
  gap: 5px;
}
.postername_p {
  background-color: var(--red) !important;
  color: var(--white) !important;
}
.note_p {
  font-size: 16pt;
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word !important;
}

#MountPosts_article {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.075);
  flex-grow: 1;
  order: 2;
}
.MountPosts_date {
  font-family: "Roboto", sans-serif;
  font-size: 11pt;
  text-align: right;
}

.note_options_div {
  justify-content: space-between;
  order: 1;
  padding-right: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 10px;
  gap: 10px;
}
.options_div {
  position: absolute;
}

.postername_p {
  background-color: var(--red);
}
.details_div {
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  order: 2;
}

#MountPosts_content_container li p {
  padding: 5px;
}

.MountPosts_postOptionsContainer {
  grid-gap: 10px;
  gap: 10px;
}

.MountPosts_postOptionsContainer p {
  background-color: #01796f;
}

@media screen and (max-width: 1000px) {
  #MountPosts_content_container {
    padding: 20px 5px;
    grid-gap: 20px;
    gap: 20px;
  }
  #MountPosts_content_container p {
    font-size: smaller;
  }
  #MountPosts_content_container .note_p {
    font-size: 14pt;
  }
  #MountPosts_content_container .comment_input {
    font-size: 10pt;
  }
  #MountPosts_content_container .comment_li {
    font-size: 12pt;
  }
}

@media screen and (max-width: 850px) {
  .details_div {
    flex-direction: column !important;
    align-items: flex-end;
  }
}

#Menu_content_container {
  display: none;
  position: absolute;
  background-color: var(--background_menu);
  height: 50%;
  width: 300px;
  overflow: auto;
  color: white;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: var(--red) 10px solid;
}
#Menu_content_container li {
  font-family: "Roboto", sans-serif;
  background-color: rgb(212, 48, 48);
  padding: 20px;
  cursor: pointer;
}

#Menu_i_close {
  display: none;
}

#FriendsList_article {
  text-align: center;
  color: white;
  font-size: 12pt;
  font-weight: 300;
  background-color: var(--black);
  overflow: auto;
  order: 3;
  height: 100%;
}

#FriendsList_i {
}

#FriendsList_centent_container {
  flex-grow: 1;
}

#FriendsList_friends_list {
  background-color: var(--black);
  grid-gap: 20px;
  gap: 20px;
}

#FriendsList_friends_list i {
  color: var(--special_black);
}
#FriendsList_friends_list div {
  background-color: var(--black2);
  color: var(--white);
  font-size: 16pt;
  padding: 20px 30px;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

#FriendsList_content_container {
  overflow: hidden;
  animation-name: width;
  animation-duration: 1s;
  width: 100%;
  padding: 30px 0;
  z-index: 1;
  grid-gap: 30px;
  gap: 30px;
}

#FriendsList_i {
  text-align: center;
  color: var(--white);
  font-size: 20pt;
}

#FriendsList_friends_list {
  flex-grow: 1;
  overflow: auto;
  overflow-wrap: break-word;
  width: 100%;
}

#FriendsList_friends_list li {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 12pt;
  text-align: center;
  padding: 20px;
  background-color: var(--black2);
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}
#FriendsList_friends_list i {
  font-size: 14pt;
}

/* Chat Fiends */

#Chat_article {
  text-align: center;
  color: white;
  font-size: 12pt;
  font-weight: 300;
  background-color: var(--black);
  height: 0;
  order: 3;
}
#Chat_title_container {
  padding: 10px;
  background-color: var(--black);
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  align-items: center;
  border-bottom: 2px rgba(255, 255, 255, 0.082) solid;
}

#Chat_title_text {
  font-size: 16pt;
  font-weight: 300;
  text-align: center;
  flex-grow: 1;
}
#Chat_goback_icon {
  font-size: 18pt;
  overflow: hidden;
  position: absolute;
  display: none;
  padding: 10px;
}

#Chat_form {
  box-shadow: 0 10px 400px rgba(255, 255, 255, 0.226);
}

#Chat_textarea_input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--black2);
  padding: 10px 20px;
  font-size: 13pt;
  color: var(--white);
  min-height: 70px;
}

#Chat_form i {
  font-size: 20pt;
  justify-content: center;
  padding: 10px;
}
#Chat_messages {
  overflow: auto;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 20px;
  padding-bottom: 70px;
  padding-top: 20px;
}

.sentMessagesLI {
  border-radius: 10px;
  background-color: var(--black2);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 70%;
}
.receivedMessagesLI {
  border-radius: 10px;
  background-color: var(--green);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.sentMessagesDIV {
  width: 100%;
  align-items: flex-end;
  overflow: hidden;
  padding: 10px;
}
.receivedMessagesDIV {
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  padding: 10px 0px;
}

#Chat_messages p {
  font-size: 14pt;
  font-family: "Roboto", sans-serif;
  padding: 5px 10px;
}
#Chat_submit_button {
  background-color: var(--red);
  border: 0;
  color: white;
  border-radius: 0;
}
@media screen and (max-width: 1200px) {
  #Chat_textarea_input {
    padding: 10px 20px;
    font-size: 12pt;
    color: var(--white);
  }
}

#Terminology_article {
  order: 1;
  z-index: 3;
}
#Terminology_title {
  background-color: #01796f;
  color: var(--white);
  padding: 18px;
  text-align: center;
  font-weight: 200;
}
#Terminology_container {
  order: 1;
  width: 0;
  overflow: hidden;
  height: 100%;
}
#Terminology_content_container {
  padding-top: 10px;
  color: white;
  overflow: auto;
  background-color: var(--black);
  grid-gap: 10px;
  gap: 10px;
  flex-grow: 1;
}
#Terminology_content_container li {
  padding: 10px 20px;
  background-color: var(--special_black);
  line-height: 30pt;
}
#Terminology_content_container p {
  font-family: "Ropa Sans", sans-serif;
  font-size: 14pt !important;
}
#Terminology_content_container i {
  color: var(--white);
  font-size: 20pt;
  padding: 10px;
  background-color: var(--special_black);
}
#Terminology_control_door {
  background-color: var(--black);
  border-right: solid rgba(0, 0, 0, 0.459) 2px;

  justify-content: center;
  align-items: center;
  order: 1;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
  z-index: 99;
}
#Terminology_control_door i {
  color: var(--white);
  font-size: 18pt;
  padding: 10px;
  animation-name: arrow_control_color;
  animation-duration: 1.5s;
}
#Terminology_inputs_container {
  background-color: #01796f;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 40px;
  align-items: center;
  box-shadow: 0 -10px 100px rgba(66, 64, 64, 0.514);
  display: none;
}
#Terminology_category,
#Terminology_meaning,
#Terminology_subject,
#Terminology_term,
#Terminology_post_button {
  border-radius: 10px;
  width: 100%;
  font-size: 11pt;
}
#Terminology_post_button {
  color: var(--white);
  background-color: var(--special_black);
  font-size: 12pt;
}
#Terminology_control_icon_open {
  background-color: var(--special_black);
  color: var(--white);
  font-size: 16pt;
  padding: 10px;
  transform: none;
  z-index: 1;
  border-bottom: rgba(0, 0, 0, 0.068) solid 2px;
  text-align: center;
}
#Terminology_control_icon_close {
  background-color: #01796f;
  color: var(--white);
  font-size: 16pt;
  padding: 10px;
  transform: none;
  z-index: 1;
  border-bottom: rgba(0, 0, 0, 0.068) solid 2px;
  height: 50px;
  display: none;
  text-align: center;
}

#Terminology_control_icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  #Terminology_container {
    overflow: hidden;
    height: 0;
    width: 100%;
  }
  #Terminology_article {
    flex-direction: column;
    display: none;
  }
  #Terminology_control_door {
    background-color: var(--black2);
    order: 1;
  }
  #Terminology_container {
    order: 2;
  }
}

#Greeting_studysessions_article {
  order: 2;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  overflow: auto;
  height: 100vh;
}

#timer_container {
  order: 3;
  font-size: 14pt;
  padding: 10px;
}

#Greeting_studySessions_area {
  color: var(--black);
  overflow: auto;
  max-height: 200px;
  padding: 10px 0;
}
#Greeting_preStart_reportDiv {
  padding: 20px;
  text-align: center;
}
#Greeting_totalDuration_li {
  font-size: 14pt;
}
#Greeting_studySessions_area div {
  padding: 10px;
  text-align: center;
}

#Greeting_preStart {
  padding-top: 50px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  flex-grow: 1;
}
#Greeting_preStart h1 {
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 45pt;
  padding: 10px;
  background-color: white;
}
#Greeting_preStart h2 {
  font-family: "Raleway", sans-serif;
  padding: 10px;
  text-align: center;
  font-size: 30pt;
  background-color: white;
}
#Greeting_preStart_button {
  justify-content: center;
  align-content: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: "Roboto", sans-serif;
  padding: 10px 20px;
  text-align: center;
  font-size: 16pt;
  width: 40%;
  background-color: #01796f;
  color: var(--white);
  border-radius: 40px;
  margin-top: 30px;
}
@media screen and (max-height: 700px) {
  #Greeting_preStart {
    transform: scale(0.8);
  }
  #Greeting_preStart h1 {
    font-size: 30pt;
  }
  #Greeting_preStart h2 {
    font-size: 20pt;
  }
  #Greeting_preStart_button {
    font-size: 15pt;
    white-space: nowrap;
  }
  #Greeting_studySessions_area div {
    font-size: 10pt;
  }
  #Greeting_studySessions_area {
    max-height: 150px;
  }
}
@media screen and (max-height: 600px) {
  #Greeting_preStart h1 {
    font-size: 25pt;
  }
  #Greeting_preStart h2 {
    font-size: 15pt;
  }
  #Greeting_preStart_button {
    font-size: 10pt;
    white-space: nowrap;
  }
  #Greeting_studySessions_area div {
    font-size: 10pt;
  }
  #Greeting_studySessions_area {
    max-height: 100px;
  }
  #i_nav_logout {
    font-size: 14pt !important;
  }
}
@media screen and (max-width: 1200px) {
  #Greeting_preStart h1 {
    font-size: 40pt;
  }
  #Greeting_preStart h2 {
    font-size: 20pt;
  }
  #Greeting_preStart_button {
    font-size: 14pt;
    white-space: nowrap;
  }
  #Greeting_studySessions_area div {
    font-size: 12pt;
  }

  #i_nav_logout {
    font-size: 18pt !important;
  }
  #Greeting_studysessions_article {
  }
  #Greeting_preStart_reportDiv h3 {
    font-size: 12pt;
  }
  #Greeting_totalDuration_li {
    font-size: 12pt;
  }
}

@media screen and (max-width: 600px) {
  #Greeting_preStart h1 {
    font-size: 25pt;
  }
  #Greeting_preStart h2 {
    font-size: 16pt;
  }
  #Greeting_preStart_button {
    font-size: 10pt;
    white-space: nowrap;
  }
  #Greeting_studySessions_area div {
    font-size: 10pt;
  }

  #i_nav_logout {
    font-size: 18pt !important;
  }
  #Greeting_studysessions_article {
  }
  #Greeting_preStart_reportDiv h3 {
    font-size: 12pt;
  }
  #Greeting_totalDuration_li {
    font-size: 12pt;
  }
}

/*......Page scope.......*/
* {
  font-family: "Raleway", sans-serif;
}
#Login_article {
  min-height: 100vh;
  background-color: var(--white);
  align-items: center;
}

/* ...........loader.............. */
#Login_loaderImg_div {
  font-size: 15pt;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

/*......Header and Loginform Container.......*/
#Login_main {
  grid-gap: 50px;
  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  /* padding: 0 30px; */
}
#Login_signupShow_text {
  cursor: pointer;
}
#Login_signupShow_text:hover {
  transform: scale(0.92);
}
#Login_loginShow_text {
  cursor: pointer;
}
#Login_loginShow_text:hover {
  transform: scale(0.92);
}

#Login_loginLogo_text {
  font-family: "Exo", sans-serif;
  font-size: 80pt;
  font-weight: 700;
  text-align: center;
  line-height: 45px;
  color: #01796f;
  position: relative;
}
#Login_subLoginLogo_text {
  font-family: "Pacifico", cursive;
  font-weight: 300;
  font-size: 35pt;
  color: var(black);
  text-align: center;
}

#Login_loginForm_container {
}

#Login_loginFrom_form {
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px;
  background-color: var(--brightestWhite);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.486);
  width: 450px;
  overflow: hidden;
}

#Login_loginFrom_form input {
  font-size: 12pt;
}

#Login_loginFrom_form button {
  background-color: #01796f;
  color: var(--brightestWhite);
  font-weight: bold;
  font-size: 14pt;
  width: 100%;
}

#Login_footer {
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 60px 100px rgba(18, 118, 175, 0.486);
  width: 100%;
  padding: 30px;
}

#Login_copyright_text {
  font-weight: 300;
}

@media screen and (max-height: 600px) {
  #Login_loginLogo_text {
    font-size: 50pt;
  }
  #Login_subLoginLogo_text {
    font-size: 30pt;
  }
  #Login_loginFrom_form {
    width: 380px;
    grid-gap: 5px;
    gap: 5px;
  }
  #Login_main {
    grid-gap: 20px;
    gap: 20px;
  }
  #Login_loginFrom_form input,
  #Login_loginFrom_form button {
    font-size: 14px;
  }
}

@media screen and (max-height: 500px) {
  #Login_loginLogo_text {
    font-size: 40pt;
  }
  #Login_subLoginLogo_text {
    font-size: 20pt;
  }
  #Login_loginFrom_form {
    width: 380px;
  }
  #Login_loginFrom_form input,
  #Login_loginFrom_form button {
    font-size: 12px;
  }
  #Login_main {
    grid-gap: 10px;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  #Login_loginLogo_text {
    font-size: 50pt;
  }
  #Login_subLoginLogo_text {
    font-size: 30pt;
  }
  #Login_loginFrom_form {
    width: 380px;
  }
}
@media screen and (max-width: 500px) {
  #Login_loginLogo_text {
    font-size: 45pt;
  }
  #Login_subLoginLogo_text {
    font-size: 25pt;
  }
  #Login_loginFrom_form {
    width: 320px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}

@media screen and (max-width: 425px) {
  #Login_loginLogo_text {
    font-size: 40pt;
  }
  #Login_subLoginLogo_text {
    font-size: 23pt;
  }
  #Login_loginFrom_form {
    width: 300px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}

@media screen and (max-width: 350px) {
  #Login_loginLogo_text {
    font-size: 35pt;
  }
  #Login_subLoginLogo_text {
    font-size: 15pt;
  }
  #Login_loginFrom_form {
    width: 250px;
  }
  #Login_copyright_text {
    font-size: 9pt;
  }
}

