/*Logo*/
#Logo_text {
  font-family: "Questrial", sans-serif;
  color: var(--white);
  text-align: center;
  letter-spacing: 1px;
  font-size: 30pt;
  padding: 10px 40px;
}
#Logo_section {
  padding: 10px;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  #Logo_text {
    font-size: 20pt;
  }
}
@media screen and (max-width: 600px) {
  #Logo_text {
    font-size: 15pt !important;
    padding: 10px;
    border-right: solid var(--white) 4px;
  }
}
